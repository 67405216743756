exports.components = {
  "component---src-pages-404-tsx": () => import("./../../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-about-us-index-tsx": () => import("./../../../src/pages/about-us/index.tsx" /* webpackChunkName: "component---src-pages-about-us-index-tsx" */),
  "component---src-pages-blog-index-tsx": () => import("./../../../src/pages/blog/index.tsx" /* webpackChunkName: "component---src-pages-blog-index-tsx" */),
  "component---src-pages-blog-mdx-frontmatter-slug-tsx-content-file-path-users-jannovergara-documents-gitlab-ccc-website-blog-benefits-of-breastfeeding-index-mdx": () => import("./../../../src/pages/blog/{mdx.frontmatter__slug}.tsx?__contentFilePath=/Users/jannovergara/Documents/Gitlab/ccc-website/blog/benefits-of-breastfeeding/index.mdx" /* webpackChunkName: "component---src-pages-blog-mdx-frontmatter-slug-tsx-content-file-path-users-jannovergara-documents-gitlab-ccc-website-blog-benefits-of-breastfeeding-index-mdx" */),
  "component---src-pages-blog-mdx-frontmatter-slug-tsx-content-file-path-users-jannovergara-documents-gitlab-ccc-website-blog-cost-of-consultation-services-index-mdx": () => import("./../../../src/pages/blog/{mdx.frontmatter__slug}.tsx?__contentFilePath=/Users/jannovergara/Documents/Gitlab/ccc-website/blog/cost-of-consultation-services/index.mdx" /* webpackChunkName: "component---src-pages-blog-mdx-frontmatter-slug-tsx-content-file-path-users-jannovergara-documents-gitlab-ccc-website-blog-cost-of-consultation-services-index-mdx" */),
  "component---src-pages-blog-mdx-frontmatter-slug-tsx-content-file-path-users-jannovergara-documents-gitlab-ccc-website-blog-five-essential-tip-for-raising-a-healthy-baby-index-mdx": () => import("./../../../src/pages/blog/{mdx.frontmatter__slug}.tsx?__contentFilePath=/Users/jannovergara/Documents/Gitlab/ccc-website/blog/five-essential-tip-for-raising-a-healthy-baby/index.mdx" /* webpackChunkName: "component---src-pages-blog-mdx-frontmatter-slug-tsx-content-file-path-users-jannovergara-documents-gitlab-ccc-website-blog-five-essential-tip-for-raising-a-healthy-baby-index-mdx" */),
  "component---src-pages-blog-mdx-frontmatter-slug-tsx-content-file-path-users-jannovergara-documents-gitlab-ccc-website-blog-five-tips-for-creating-safe-sleep-environment-index-mdx": () => import("./../../../src/pages/blog/{mdx.frontmatter__slug}.tsx?__contentFilePath=/Users/jannovergara/Documents/Gitlab/ccc-website/blog/five-tips-for-creating-safe-sleep-environment/index.mdx" /* webpackChunkName: "component---src-pages-blog-mdx-frontmatter-slug-tsx-content-file-path-users-jannovergara-documents-gitlab-ccc-website-blog-five-tips-for-creating-safe-sleep-environment-index-mdx" */),
  "component---src-pages-blog-mdx-frontmatter-slug-tsx-content-file-path-users-jannovergara-documents-gitlab-ccc-website-blog-how-to-book-an-appointment-online-consultation-index-mdx": () => import("./../../../src/pages/blog/{mdx.frontmatter__slug}.tsx?__contentFilePath=/Users/jannovergara/Documents/Gitlab/ccc-website/blog/how-to-book-an-appointment-online-consultation/index.mdx" /* webpackChunkName: "component---src-pages-blog-mdx-frontmatter-slug-tsx-content-file-path-users-jannovergara-documents-gitlab-ccc-website-blog-how-to-book-an-appointment-online-consultation-index-mdx" */),
  "component---src-pages-blog-mdx-frontmatter-slug-tsx-content-file-path-users-jannovergara-documents-gitlab-ccc-website-blog-how-to-protect-babies-and-toddlers-from-summer-heat-index-mdx": () => import("./../../../src/pages/blog/{mdx.frontmatter__slug}.tsx?__contentFilePath=/Users/jannovergara/Documents/Gitlab/ccc-website/blog/how-to-protect-babies-and-toddlers-from-summer-heat/index.mdx" /* webpackChunkName: "component---src-pages-blog-mdx-frontmatter-slug-tsx-content-file-path-users-jannovergara-documents-gitlab-ccc-website-blog-how-to-protect-babies-and-toddlers-from-summer-heat-index-mdx" */),
  "component---src-pages-blog-mdx-frontmatter-slug-tsx-content-file-path-users-jannovergara-documents-gitlab-ccc-website-blog-importance-of-well-child-visits-index-mdx": () => import("./../../../src/pages/blog/{mdx.frontmatter__slug}.tsx?__contentFilePath=/Users/jannovergara/Documents/Gitlab/ccc-website/blog/importance-of-well-child-visits/index.mdx" /* webpackChunkName: "component---src-pages-blog-mdx-frontmatter-slug-tsx-content-file-path-users-jannovergara-documents-gitlab-ccc-website-blog-importance-of-well-child-visits-index-mdx" */),
  "component---src-pages-blog-mdx-frontmatter-slug-tsx-content-file-path-users-jannovergara-documents-gitlab-ccc-website-blog-introducing-solids-index-mdx": () => import("./../../../src/pages/blog/{mdx.frontmatter__slug}.tsx?__contentFilePath=/Users/jannovergara/Documents/Gitlab/ccc-website/blog/introducing-solids/index.mdx" /* webpackChunkName: "component---src-pages-blog-mdx-frontmatter-slug-tsx-content-file-path-users-jannovergara-documents-gitlab-ccc-website-blog-introducing-solids-index-mdx" */),
  "component---src-pages-blog-mdx-frontmatter-slug-tsx-content-file-path-users-jannovergara-documents-gitlab-ccc-website-blog-knowing-when-to-consult-a-pedia-for-worsening-flu-and-cough-in-children-index-mdx": () => import("./../../../src/pages/blog/{mdx.frontmatter__slug}.tsx?__contentFilePath=/Users/jannovergara/Documents/Gitlab/ccc-website/blog/knowing-when-to-consult-a-pedia-for-worsening-flu-and-cough-in-children/index.mdx" /* webpackChunkName: "component---src-pages-blog-mdx-frontmatter-slug-tsx-content-file-path-users-jannovergara-documents-gitlab-ccc-website-blog-knowing-when-to-consult-a-pedia-for-worsening-flu-and-cough-in-children-index-mdx" */),
  "component---src-pages-blog-mdx-frontmatter-slug-tsx-content-file-path-users-jannovergara-documents-gitlab-ccc-website-blog-leptospirosis-in-children-index-mdx": () => import("./../../../src/pages/blog/{mdx.frontmatter__slug}.tsx?__contentFilePath=/Users/jannovergara/Documents/Gitlab/ccc-website/blog/leptospirosis-in-children/index.mdx" /* webpackChunkName: "component---src-pages-blog-mdx-frontmatter-slug-tsx-content-file-path-users-jannovergara-documents-gitlab-ccc-website-blog-leptospirosis-in-children-index-mdx" */),
  "component---src-pages-blog-mdx-frontmatter-slug-tsx-content-file-path-users-jannovergara-documents-gitlab-ccc-website-blog-recognizing-and-managing-common-childhood-illnesses-index-mdx": () => import("./../../../src/pages/blog/{mdx.frontmatter__slug}.tsx?__contentFilePath=/Users/jannovergara/Documents/Gitlab/ccc-website/blog/recognizing-and-managing-common-childhood-illnesses/index.mdx" /* webpackChunkName: "component---src-pages-blog-mdx-frontmatter-slug-tsx-content-file-path-users-jannovergara-documents-gitlab-ccc-website-blog-recognizing-and-managing-common-childhood-illnesses-index-mdx" */),
  "component---src-pages-blog-mdx-frontmatter-slug-tsx-content-file-path-users-jannovergara-documents-gitlab-ccc-website-blog-seven-signs-to-baby-development-milestone-index-mdx": () => import("./../../../src/pages/blog/{mdx.frontmatter__slug}.tsx?__contentFilePath=/Users/jannovergara/Documents/Gitlab/ccc-website/blog/seven-signs-to-baby-development-milestone/index.mdx" /* webpackChunkName: "component---src-pages-blog-mdx-frontmatter-slug-tsx-content-file-path-users-jannovergara-documents-gitlab-ccc-website-blog-seven-signs-to-baby-development-milestone-index-mdx" */),
  "component---src-pages-blog-mdx-frontmatter-slug-tsx-content-file-path-users-jannovergara-documents-gitlab-ccc-website-blog-soothe-a-crying-baby-index-mdx": () => import("./../../../src/pages/blog/{mdx.frontmatter__slug}.tsx?__contentFilePath=/Users/jannovergara/Documents/Gitlab/ccc-website/blog/soothe-a-crying-baby/index.mdx" /* webpackChunkName: "component---src-pages-blog-mdx-frontmatter-slug-tsx-content-file-path-users-jannovergara-documents-gitlab-ccc-website-blog-soothe-a-crying-baby-index-mdx" */),
  "component---src-pages-blog-mdx-frontmatter-slug-tsx-content-file-path-users-jannovergara-documents-gitlab-ccc-website-blog-ten-essential-vaccines-for-your-babys-first-year-index-mdx": () => import("./../../../src/pages/blog/{mdx.frontmatter__slug}.tsx?__contentFilePath=/Users/jannovergara/Documents/Gitlab/ccc-website/blog/ten-essential-vaccines-for-your-babys-first-year/index.mdx" /* webpackChunkName: "component---src-pages-blog-mdx-frontmatter-slug-tsx-content-file-path-users-jannovergara-documents-gitlab-ccc-website-blog-ten-essential-vaccines-for-your-babys-first-year-index-mdx" */),
  "component---src-pages-blog-mdx-frontmatter-slug-tsx-content-file-path-users-jannovergara-documents-gitlab-ccc-website-blog-the-benefits-of-saline-nasal-drops-relief-for-congestion-and-beyond-index-mdx": () => import("./../../../src/pages/blog/{mdx.frontmatter__slug}.tsx?__contentFilePath=/Users/jannovergara/Documents/Gitlab/ccc-website/blog/the-benefits-of-saline-nasal-drops-relief-for-congestion-and-beyond/index.mdx" /* webpackChunkName: "component---src-pages-blog-mdx-frontmatter-slug-tsx-content-file-path-users-jannovergara-documents-gitlab-ccc-website-blog-the-benefits-of-saline-nasal-drops-relief-for-congestion-and-beyond-index-mdx" */),
  "component---src-pages-blog-mdx-frontmatter-slug-tsx-content-file-path-users-jannovergara-documents-gitlab-ccc-website-blog-the-vital-importance-of-clinic-schedule-adherence-index-mdx": () => import("./../../../src/pages/blog/{mdx.frontmatter__slug}.tsx?__contentFilePath=/Users/jannovergara/Documents/Gitlab/ccc-website/blog/the-vital-importance-of-clinic-schedule-adherence/index.mdx" /* webpackChunkName: "component---src-pages-blog-mdx-frontmatter-slug-tsx-content-file-path-users-jannovergara-documents-gitlab-ccc-website-blog-the-vital-importance-of-clinic-schedule-adherence-index-mdx" */),
  "component---src-pages-blog-mdx-frontmatter-slug-tsx-content-file-path-users-jannovergara-documents-gitlab-ccc-website-blog-to-co-sleep-or-not-index-mdx": () => import("./../../../src/pages/blog/{mdx.frontmatter__slug}.tsx?__contentFilePath=/Users/jannovergara/Documents/Gitlab/ccc-website/blog/to-co-sleep-or-not/index.mdx" /* webpackChunkName: "component---src-pages-blog-mdx-frontmatter-slug-tsx-content-file-path-users-jannovergara-documents-gitlab-ccc-website-blog-to-co-sleep-or-not-index-mdx" */),
  "component---src-pages-blog-mdx-frontmatter-slug-tsx-content-file-path-users-jannovergara-documents-gitlab-ccc-website-blog-what-is-online-consultation-index-mdx": () => import("./../../../src/pages/blog/{mdx.frontmatter__slug}.tsx?__contentFilePath=/Users/jannovergara/Documents/Gitlab/ccc-website/blog/what-is-online-consultation/index.mdx" /* webpackChunkName: "component---src-pages-blog-mdx-frontmatter-slug-tsx-content-file-path-users-jannovergara-documents-gitlab-ccc-website-blog-what-is-online-consultation-index-mdx" */),
  "component---src-pages-contact-us-index-tsx": () => import("./../../../src/pages/contact-us/index.tsx" /* webpackChunkName: "component---src-pages-contact-us-index-tsx" */),
  "component---src-pages-for-parents-index-tsx": () => import("./../../../src/pages/for-parents/index.tsx" /* webpackChunkName: "component---src-pages-for-parents-index-tsx" */),
  "component---src-pages-for-parents-new-patient-form-tsx": () => import("./../../../src/pages/for-parents/new-patient-form.tsx" /* webpackChunkName: "component---src-pages-for-parents-new-patient-form-tsx" */),
  "component---src-pages-for-parents-online-consultation-form-tsx": () => import("./../../../src/pages/for-parents/online-consultation-form.tsx" /* webpackChunkName: "component---src-pages-for-parents-online-consultation-form-tsx" */),
  "component---src-pages-index-tsx": () => import("./../../../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-using-typescript-tsx": () => import("./../../../src/pages/using-typescript.tsx" /* webpackChunkName: "component---src-pages-using-typescript-tsx" */)
}

